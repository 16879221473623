import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import AboutView from '../views/AboutView.vue'

// import Vue from 'vue';
// import Timer from '../views/Timer.vue';

// Vue.component('timer', Timer);

// new Vue({
//   el: '#app',
//   // ...
// });




const routes = [
  
  {
    path: '/',
    name: '当前时间',
    component: HomeView
  },
  {
    path: '/about',
    name: '高考倒计时',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/mytime',
    name: '我的倒计时',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Mytime.vue')
  },
  {
    path: '/download',
    name: '下载桌面',   
    //component: () => import(/* webpackChunkName: "about" */ '../views/OtherCity.vue')
    component: () => import(/* webpackChunkName: "about" */ '../views/download.vue')
    // component: DownloadComponent
  },
  
  {
    path: '/resttime',
    name: '计时器',       
    component: () => import(/* webpackChunkName: "about" */ '../views/Resttime.vue')    
  },
  {
    path: '/othercity',
    name: '其它城市',   
    //component: () => import(/* webpackChunkName: "about" */ '../views/OtherCity.vue')
    component: () => import(/* webpackChunkName: "about" */ '../views/OtherCity.vue')
    // component: DownloadComponent
  }



]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
