import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// createApp(App).use(router).mount('#app')


import Timer from './components/Timer.vue';

const app = createApp(App);

app.component('timer', Timer); // 将Timer组件注册为局部组件

app.use(router); // 使用Vue Router

app.mount('#app');
