<template>
    <div>
      <input type="number" v-model="time" placeholder="输入秒数"  max="3600" style="width: 140px"/>
      <button  @click="toggleTimer">{{ isBegin ? '开始' : isRunning ? '暂停':'继续' }}</button>
      <div ref="timer" :class="{ timer: true, red: isCountdown }">{{ formatTime }}</div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        time: '',
        timer: null,
        isRunning: false,
        isBegin: true,
      };
    },
    computed: {
      formatTime() {
        let minutes = Math.floor(this.time / 60);
        let seconds = this.time % 60;
        return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
      },
      isCountdown() {
        return this.time <= 5 && this.time > 0;
      },
    },
    methods: {
      toggleTimer() {
          this.isBegin=false;
        if (this.isRunning) {
          clearInterval(this.timer);
          this.isRunning = false;        
        } else {
          this.timer = setInterval(() => {
            this.time--;
          //   if (this.time <= 5 && this.time > 0) {
              if (this.time <= 5) {
              this.$refs.timer.classList.add('red');
              new Audio('http://soundbible.com/mp3/Beep-SoundBible.com-923660219.mp3').play();
            }
            if (this.time < 0) {
              this.stopTimer();
            }
          }, 1000);
          this.isRunning = true;
          //this.isBegin=true;        
        }
      },
      stopTimer() {
        clearInterval(this.timer);      
      //   this.$refs.timer.classList.remove('red');
        this.time = 0;
        this.isRunning = false;
        this.isBegin=true;
      },
    },
  };
  </script>
  
  <style scoped>
  div {
    font-size: 2em;
    text-align: center;
    margin-top: 20px;
  }
  
  button {
    /* font-size: 1em; */
    font-size:30px;
    width:120px;
    margin: 15px;
    padding: 3px 10px;
  }
  
  input {
    font-size: 28px;
    height:34px;
    padding: 5px;
  }
  
  .timer {
    font-size: 3em;
  }
  
  .red {
    color: red;
  }
  </style>
  